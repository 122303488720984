import { useEffect, useState } from 'react';
import { RestApiClient } from '@shahadul-17/rest-api-client';
import Styles from  "./DynamicField.module.scss";
import { StringUtilities } from '../../utilities';
import { Autocomplete, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DateTimePicker, DesktopDatePicker, MobileDatePicker, TimePicker } from '@mui/x-date-pickers';
import { MdEdit, MdDelete, MdSave } from "react-icons/md";

/**
 * Deletes a dynamic field by ID.
 * @param {String} dynamicFieldId ID of the field that shall be deleted.
 * @returns 
 */
const deleteFieldAsync = async dynamicFieldId => {
  const restApiClient = RestApiClient.getInstance();
  const response = await restApiClient.sendSmartRequestAsync({
    routeName: 'deleteDynamicField',
    data: { dynamicFieldId: dynamicFieldId, },
  });

  return response;
};

export const DynamicField = ({ fieldId, fieldLabel, fieldType, fieldData, fieldKey, fieldValue, onValueChange, onSaveClick, onFieldEdit, onFieldDelete, }) => {
  const [value, setValue] = useState(fieldValue);

  const onInputValueChangedAsync = async event => {
    // checks if 'onValueChange' is a function...
    if (typeof onValueChange !== 'function') { return; }

    let value = event.target.value;

    // if event was generated by input type number...
    if (event.target.type === 'number') {
      // converts value to number...
      value = Number(value);

      // if for some reason, value is not a number, we shall assign zero...
      if (isNaN(value)) { value = 0; }
    }

    // if event was generated by input type checkbox...
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }

    setValue(value);

    await onValueChange({
      fieldId, fieldLabel, fieldType, fieldKey, fieldValue: value, event,
    });
  };

  const onSaveButtonClickedAsync = async event => {
    // checks if 'onSaveClick' is a function...
    if (typeof onSaveClick !== 'function') { return; }

    await onSaveClick({
      fieldId, fieldLabel, fieldType, fieldKey, fieldValue: value, event,
    });
  };

  const onFieldEditButtonClickedAsync = async event => {
    if (typeof onFieldEdit !== 'function') { return; }

    await onFieldEdit({ fieldId, fieldLabel, fieldKey, fieldType, event, });
  };

  const onFieldDeleteButtonClickedAsync = async event => {
    if (typeof onFieldDelete !== 'function') { return; }
    if (!window.confirm(`Are you sure you want to delete '${fieldLabel}'?`)) { return; }

    const deleteResponse = await deleteFieldAsync(fieldId);

    await onFieldDelete({ fieldId, fieldLabel, fieldKey, fieldType, event, response: deleteResponse, });
  };

  /**
   * @param {String} data 
   * @returns 
   */
  const parseDataAsLabelValuePairs = data => {
    if (StringUtilities.isUndefinedOrNullOrEmpty(data, true)) { return []; }

    const splittedLabelValuePairs = data.split(';');
    const labelValuePairs = [];

    for (const splittedLabelValuePair of splittedLabelValuePairs) {
      const splittedLabelAndValue = splittedLabelValuePair.split('-');

      if (splittedLabelAndValue.length < 2) { continue; }

      labelValuePairs.push({
        label: splittedLabelAndValue[0],
        value: splittedLabelAndValue[1],
      });
    }

    return labelValuePairs;
  };

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  return <>
    <div className={Styles.container}>
      <div className={Styles.main}>
        <div  className={Styles.titleContainer}>
          {!['FREE_SOLO_AUTOCOMPLETE'].includes(fieldType) && <label className={Styles.label} htmlFor={fieldKey}>{fieldLabel}</label>}
        </div>
       
        <div className={Styles.fieldContainer}>
            {fieldType === 'NUMERIC' && <input className={Styles.input} type='number' name={fieldKey} value={value} onChange={onInputValueChangedAsync} />}
            {fieldType === 'SINGLELINE_TEXT' && <input className={Styles.input} type='text' name={fieldKey} value={value} onChange={onInputValueChangedAsync} />}
            {fieldType === 'MULTILINE_TEXT' && <textarea className={Styles.textarea} name={fieldKey} value={value} onChange={onInputValueChangedAsync} />}
            {fieldType === 'CHECKBOX' && <input className={Styles.checkbox} type='checkbox' name={fieldKey} checked={value} onChange={onInputValueChangedAsync} />}
            {fieldType === 'SELECT' && <select className={Styles.select}  name={fieldKey} value={value} onChange={onInputValueChangedAsync}>
              {parseDataAsLabelValuePairs(fieldData).map(labelValuePair => {
                return <option key={labelValuePair.value} value={labelValuePair.value}>{labelValuePair.label}</option>
              })}
            </select>}
            {fieldType === 'FREE_SOLO_AUTOCOMPLETE' && <Autocomplete
                size='small'
                freeSolo={true}
                value={value}
                options={[]}
                multiple={true}
                autoComplete={false}
                onChange={async (_, value) => await onInputValueChangedAsync({ target: { name: fieldKey, value: value, } })}
                renderInput={params => <TextField {...params} label={fieldLabel} />} />}
            {(fieldType === 'SINGLE_AUTOCOMPLETE' || fieldType === 'MULTIPLE_AUTOCOMPLETE')/*  && fieldKey !== 'buildingFacing' */ && <Autocomplete
                value={value ?? (fieldType === 'MULTIPLE_AUTOCOMPLETE' ? [] : '')}
                size='small'
                multiple={fieldType === 'MULTIPLE_AUTOCOMPLETE'}
                options={parseDataAsLabelValuePairs(fieldData)}
                getOptionLabel={option => option.label ?? ''}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={async (_, value) => await onInputValueChangedAsync({ target: { name: fieldKey, value: value ?? (fieldType === 'MULTIPLE_AUTOCOMPLETE' ? [] : ''), } })}
                renderInput={params => <TextField {...params} label={fieldLabel} />} />}
            {fieldType === 'COMPLETE_DATE_DESKTOP' && <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label={fieldLabel}
                value={value}
                inputFormat="MM/dd/yyyy"
                onChange={async (value, keyboardInputValue) => await onInputValueChangedAsync({ target: { name: fieldKey, value, keyboardInputValue, } })}
                renderInput={params => <TextField {...params} />} />
            </LocalizationProvider>}
            {fieldType === 'COMPLETE_DATE_MOBILE' && <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label={fieldLabel}
                value={value}
                inputFormat="MM/dd/yyyy"
                onChange={async (value, keyboardInputValue) => await onInputValueChangedAsync({ target: { name: fieldKey, value, keyboardInputValue, } })}
                renderInput={params => <TextField {...params} />} />
            </LocalizationProvider>}
            {fieldType === 'TIME_ONLY_DATE' && <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker label={fieldLabel} value={value}
                renderInput={params => <TextField {...params} />}
                onChange={async (value, keyboardInputValue) => await onInputValueChangedAsync({ target: { name: fieldKey, value, keyboardInputValue, } })} />
            </LocalizationProvider>}
            {fieldType === 'YEAR_ONLY_DATE' && <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker views={['year']} label={fieldLabel} value={value}
                renderInput={params => <TextField {...params} helperText={null} />}
                onChange={async (value, keyboardInputValue) => await onInputValueChangedAsync({ target: { name: fieldKey, value, keyboardInputValue, } })} />
            </LocalizationProvider>}
            {fieldType === 'TIME_AND_DATE' && <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker label={fieldLabel} value={value}
                renderInput={params => <TextField {...params} />}
                onChange={async (value, keyboardInputValue) => await onInputValueChangedAsync({ target: { name: fieldKey, value, keyboardInputValue, } })} />
            </LocalizationProvider>}
        </div>
        <div className={Styles.btnContainer}>
          <MdEdit className={Styles.btnEdit} onClick={onFieldEditButtonClickedAsync}/>
          <MdDelete className={Styles.btnDelete}   onClick={onFieldDeleteButtonClickedAsync}/>
          {typeof onSaveClick === 'function' && < MdSave className={Styles.btnSave} onClick={onSaveButtonClickedAsync}/>}
          
          {/* <button type='button' onClick={onFieldEditButtonClickedAsync}>Edit Field</button>
          <button type='button' onClick={onFieldDeleteButtonClickedAsync}>Delete Field</button>
          {typeof onSaveClick === 'function' && <button type='button' onClick={onSaveButtonClickedAsync}>Save</button>} */}
        </div>
      </div>
    </div>
  </>
};
