import { useMemo } from 'react';

const DEFAULT_DEBOUNCE_TIMEOUT_IN_MILLISECONDS = 450;

export const DebouncedTextInput = ({ debounceTimeoutInMilliseconds, onDebounce, onChange, ...props }) => {

  const memoizedData = useMemo(() => {
    return {
      timeoutHandle: undefined,
    };
  }, []);

  const onTextChangedAsync = async event => {
    // clears timeout if running...
    memoizedData.timeoutHandle && window.clearTimeout(memoizedData.timeoutHandle);
    // calls 'onChange' callback if present...
    typeof onChange === 'function' && await onChange(event);
    // sets new timeout...
    memoizedData.timeoutHandle = window.setTimeout(async () => {
      typeof onDebounce === 'function' && await onDebounce(event);
    }, debounceTimeoutInMilliseconds ?? DEFAULT_DEBOUNCE_TIMEOUT_IN_MILLISECONDS);
  };

  return <>
    <input {...props} type='text' onChange={onTextChangedAsync} />
  </>;
};
